import { useHistory } from 'react-router-dom';
import { Row, Button } from 'reactstrap';

import info from '../../_assets/img/icons/client/Group 5@3x.png';
import upload from '../../_assets/img/icons/client/Group 6@3x.png';
import linkIcon from '../../_assets/img/icons/client/Group 7@3x.png';
import plus from '../../_assets/img/icons/client/Group 19@3x.png';
import contactUs from '../../_assets/img/icons/client/contact-us@3x.png';
import help from '../../_assets/img/icons/client/help@3x.png';
import portfolioIcon from '../../_assets/img/icons/client/import-portfolio-apl.svg';
import key from '../../_assets/img/icons/client/key-hole@3x.png';
import profileIcon from '../../_assets/img/icons/client/profile-with-background.svg';
import profile from '../../_assets/img/icons/client/profile@3x2.png';

import './client.scss';

const EmptyClientList = () => {
  const history = useHistory();

  return (
    <div className="shortcuts-container">
      <Row className="client-list-shortcut">
        <div className="shortcut-item">
          <img src={profileIcon} alt="import a list" className="shortcut-image-left" />
          <Button color="link" onClick={() => history.push('/clients/importClients')}>
            <h5 className="shortcut-text">
              Import a <br /> Client List
            </h5>
          </Button>
          <div className="shortcut-image">
            <div>
              <img src={upload} alt="upload" className="shortcut-image-right" />
            </div>
          </div>
        </div>

        <div className="shortcut-item">
          <img src={profileIcon} alt="import a list" className="shortcut-image-left" />
          <Button color="link" onClick={() => history.push('/clients/add')}>
            <h5 className="shortcut-text">Add a Client</h5>
          </Button>
          <div className="shortcut-image">
            <div>
              <img src={plus} alt="plus" className="shortcut-image-right" />
            </div>
          </div>
        </div>

        <div className="shortcut-item">
          <img src={portfolioIcon} alt="import a list" className="shortcut-image-left" />
          <Button color="link" onClick={() => history.push('/account/settings')}>
            <h5 className="shortcut-text">
              Connect to <br /> your Xplan
            </h5>
          </Button>
          <div className="shortcut-image">
            <div>
              <img src={key} alt="key" className="shortcut-image-right" />
            </div>
          </div>
        </div>

        <div className="shortcut-item">
          <img src={portfolioIcon} alt="import a list" className="shortcut-image-left" />
          <Button color="link" onClick={() => history.push('/clients/portfoliosUpload')}>
            <h5 className="shortcut-text">
              Import a <br /> Portfolio
            </h5>
          </Button>
          <div className="shortcut-image">
            <div>
              <img src={upload} alt="upload" className="shortcut-image-right" />
            </div>
          </div>
        </div>

        <div className="shortcut-item">
          <img src={portfolioIcon} alt="import a list" className="shortcut-image-left" />
          <Button color="link" onClick={() => history.push('/aplUpload')}>
            <h5 className="shortcut-text">
              Import a <br /> APL
            </h5>
          </Button>
          <div className="shortcut-image">
            <div>
              {' '}
              <img src={upload} alt="upload" className="shortcut-image-right" />
            </div>
          </div>
        </div>

        <div className="shortcut-item">
          <img src={help} alt="import a list" className="shortcut-image-left info" />
          <Button color="link" onClick={() => history.push('account/faqs')}>
            <h5 className="shortcut-text">
              FAQs and <br /> Support
            </h5>
          </Button>
          <div className="shortcut-image">
            <div>
              <img src={info} alt="upload" className="shortcut-image-right" />
            </div>
          </div>
        </div>

        <div className="shortcut-item">
          <img src={linkIcon} alt="import a list" className="shortcut-image-left link" />
          <a
            href={`https://okoadviser.com/category/help-and-guides/`}
            target="_blank"
            rel="noreferrer"
            style={{ margin: 'auto' }}
          >
            <h5 className="shortcut-text">
              Additional <br /> Resources
            </h5>
          </a>
          <div className="shortcut-image">
            <div>
              <img src={info} alt="info" className="shortcut-image-right" />
            </div>
          </div>
        </div>

        <div className="shortcut-item">
          <img src={profile} alt="import a list" className="shortcut-image-left link" />
          <Button color="link" onClick={() => history.push('/account')}>
            <h5 className="shortcut-text">
              Adviser <br /> Center
            </h5>
          </Button>
          <div className="shortcut-image">
            <div>
              <img src={info} alt="info" className="shortcut-image-right" />
            </div>
          </div>
        </div>

        <div className="shortcut-item">
          <img src={contactUs} alt="import a list" className="shortcut-image-left info" />
          <a
            href={`https://okoadviser.com/contact/`}
            target="_blank"
            rel="noreferrer"
            style={{ margin: 'auto' }}
          >
            <h5 className="shortcut-text">Contact Us</h5>
          </a>
          <div className="shortcut-image">
            <div>
              {' '}
              <img src={info} alt="upload" className="shortcut-image-right" />
            </div>
          </div>
        </div>
      </Row>
    </div>
  );
};
export default EmptyClientList;
