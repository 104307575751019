import React from 'react';

import { useHistory, useParams } from 'react-router-dom';
import { Button, Col, Container, Row } from 'reactstrap';

import linkProfileBuilder from './../../../_assets/img/icons/profileIntro/linkProfileBuilder.svg';

export default function Wellcome() {
  const history = useHistory();
  const { id } = useParams();
  const path = `/clients/${id}/investing-profile/lifestyles`;

  return (
    <>
      <Container className="investing-profile">
        <Row className="mt-5 mb-5 justify-content-center">
          <p
            style={{ fontSize: '32px' }}
            className="text-center welcome-header mt-30"
          >{`Welcome to your profile builder.`}</p>
        </Row>
        <Row className="mb-5 justify-content-center">
          <img style={{ width: '312px', height: '266px' }} src={linkProfileBuilder} width="300" />
        </Row>
        <Row className="mb-3">
          <p
            className="text-center mb-5"
            style={{ fontSize: '22px' }}
          >{`Your Adviser has sent you this link so you can create your personalised Responsible Investing Profile. By determining the Lifestyle and Business behaviors that you want to support and avoid, your Adviser can start to align your investments with your sustainable values.`}</p>
        </Row>
        <Row className="justify-content-center">
          <Col sm={5} className="mb-5">
            <div>
              <Button
                className="text-center btn-main-shadowless"
                onClick={() => history.push(path)}
              >
                Let&apos;s go &rarr;
              </Button>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}
